import React, { useMemo } from "react";
import _ from "lodash";

import RevenueMerchandise from "./RevenueMerchandise";
import RevenueSuperClubSeasonSummary from "./RevenueSuperClubSeasonSummary";
import RevenueSeason from "./RevenueSeason";
import { displaySeason } from "../../../utils/helpers";
import TlPaper from "../../../components/TlPaper";

export default function RevenuesReportSuperClub(props) {
  const seasons = useMemo(
    () => _.groupBy(props.seasons, (s) => s.season.year),
    [props.seasons]
  );

  return (
    <div>
      <TlPaper
        title="סיכום הכנסות עונתיות"
        titleBackground
        isLoading={props.isLoading}
        paperSx={styles.paper}
      >
        {Object.entries(seasons).map(([year, branchSeasons]) => (
          <RevenueSuperClubSeasonSummary
            key={year}
            year={year}
            seasons={branchSeasons}
          />
        ))}
      </TlPaper>

      {Object.entries(seasons).map(([year, branchSeasons]) => (
        <TlPaper
          key={year}
          title={`הכנסות לעונת ${displaySeason(year)} לפי ענף`}
          titleBackground
          paperSx={styles.paper}
        >
          {(branchSeasons || []).map((season) => (
            <RevenueSeason
              season={season}
              key={season.season._id}
              onDownloadSeason={props.onDownloadSeason}
            />
          ))}
        </TlPaper>
      ))}

      <TlPaper title="חנות" titleBackground>
        <RevenueMerchandise
          dates={props.filters.date}
          merchandise={props.merchandise}
          onDownload={props.onDownloadMerchandise}
          onDateChanged={props.onMerchandiseDateChange}
          isLoading={props.isLoadingMerchandise}
          superClub
        />
      </TlPaper>
    </div>
  );
}

const styles = {
  paper: {
    marginBottom: "30px",
  },
};

import React, { useMemo } from "react";
import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import moment from "moment";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import RefreshIcon from "@mui/icons-material/Refresh";
import { CircularProgress, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";

import "./calendar.scss";
import Toolbar from "./Toolbar";
import theme from "../../theme/muiTheme";
import CustomAgenda from "../../components/calendar/CustomAgenda";
import AbsoluteCenter from "../AbsoluteCenter";
import EventToolTip from "./EventToolTip";
import ResponsiveContainer from "../ResponsiveContainer";
import ToolbarMobile from "./ToolbarMobile";

const DnDCalendar = withDragAndDrop(BigCalendar);
const DnDCalendarStyled = styled(DnDCalendar)({
  height: "calc(100vh - 230px)",
});

const CalendarStyled = styled(BigCalendar)(({ theme }) => ({
  height: "calc(100vh - 160px)",
  [theme.breakpoints.up("sm")]: {
    height: "calc(100vh - 290px)",
  },
}));

export default function Calendar(props) {
  const localizer = momentLocalizer(moment);
  const agendaLength = 6;
  const isMdUp = useMediaQuery((t) => t.breakpoints.up("md"), { noSsr: true });

  const formatTime = (time) => {
    const t = moment(time);
    return t.format(`HH:mm`);
  };

  const renderEvent = (showTime) =>
    function Event(info) {
      const { event } = info;
      if (event.allDay) {
        showTime = false;
      }
      const timeDiv = (
        <span style={{ fontSize: "80%" }}>
          {", "}
          {formatTime(event.start)} - {formatTime(event.end)}
        </span>
      );
      const regularEvent = (
        <EventToolTip
          event={event}
          formatTime={formatTime}
          holiday={event.holiday}
        >
          <div
            style={{
              fontSize: 14,
              ...(event.holiday ? { cursor: "auto" } : {}),
            }}
          >
            {event.shouldSendCoachNotification && !event.started
              ? `\u2605`
              : ""}
            {event.title.replace("אימון - ", "אימון ")}
            {showTime && timeDiv}
            {event.isRecurringEvent && (
              <RefreshIcon style={{ fontSize: 12, marginRight: 5 }} />
            )}
          </div>
        </EventToolTip>
      );

      return event.cloneType === "placeholder" ? regularEvent : regularEvent;
    };

  let dateRangeFormat = ({ start, end }, culture, local) =>
    local.format(start, "L", culture) + " – " + local.format(end, "L", culture);

  let timeRangeFormat = () => {
    return "";
  };

  let timeRangeStartFormat = ({ start }, culture, local) =>
    local.format(start, "LT", culture) + " –---- ";

  let timeRangeEndFormat = ({ end }, culture, local) =>
    " –__ " + local.format(end, "LT", culture);

  let formats = {
    dateFormat: "DD",
    dayFormat: "DD ddd",
    weekdayFormat: "ddd",

    selectRangeFormat: timeRangeFormat,
    eventTimeRangeFormat: timeRangeFormat,
    eventTimeRangeStartFormat: timeRangeStartFormat,
    eventTimeRangeEndFormat: timeRangeEndFormat,

    timeGutterFormat: "HH:mm",

    monthHeaderFormat: "MMMM YYYY",
    dayHeaderFormat: "dddd MMM DD",
    dayRangeHeaderFormat: dateRangeFormat,
    agendaHeaderFormat: dateRangeFormat,

    agendaDateFormat: "ddd MMM DD",
    agendaTimeFormat: "LT",
    agendaTimeRangeFormat: timeRangeFormat,
  };

  const eventStyleGetter = (event) => {
    var style = {
      textAlign: "right",
      color: "#000000",
    };
    const team = props.teams[event.team];
    if (!(props.currentView === "week" && props.weekViewType === "table")) {
      style.color = "#FFFFFF";
      style.backgroundColor =
        event.type === "game"
          ? event.gameDetails.gameType === "home"
            ? theme.calendar.homeGame
            : theme.calendar.awayGame
          : team && team.teamType === "league"
          ? theme.calendar.league
          : theme.calendar.school;

      if (event.type === "other") {
        style.backgroundColor = theme.calendar.other;
      }

      if (event.cloneType === "placeholder") {
        style.backgroundColor = theme.calendar.placeholder;
      }
    }

    if (event.hasCollisions) {
      style.backgroundColor = theme.calendar.collisions;
    }

    if (event.holiday) {
      style.backgroundColor = theme.calendar.holiday;
    }

    return {
      style: style,
    };
  };

  const dateRange = (date, view) => {
    let start, end;
    switch (view) {
      case "day":
        start = moment(date).startOf("day");
        end = moment(date).endOf("day");
        break;

      case "agenda":
      case "week":
        start = moment(date).startOf("week");
        end = moment(date).endOf("week");
        break;

      case "month":
        start = moment(date).startOf("month");
        end = moment(date).endOf("month");
        break;

      default:
        break;
    }
    return { start, end };
  };

  const onViewChanged = (view) => {
    const currentDate =
      props.currentView === "agenda"
        ? moment(props.currentDate).startOf("week")
        : props.currentDate;

    const { start, end } = dateRange(props.currentDate, view);
    props.onCalendarChange(view, currentDate, start, end);
  };

  const onNavigate = (date, view) => {
    const { start, end } = dateRange(moment(date), view);
    props.onCalendarChange(view, moment(date), start, end);
  };

  const onDateChanged = (changeType) => {
    let newDate;
    switch (changeType) {
      case "today":
        newDate = moment().startOf("day");
        break;

      case "next":
        newDate = moment(props.currentDate).add(
          props.currentView === "day" ? 1 : 7,
          "days"
        );
        break;

      case "prev":
        newDate = moment(props.currentDate).add(
          props.currentView === "day" ? -1 : -7,
          "days"
        );
        break;

      default:
        throw new Error("unimplemented");
    }

    const { start, end } = dateRange(newDate, props.currentView);
    props.onCalendarChange(props.currentView, newDate, start, end);
  };

  const Calendar = useMemo(
    () => (isMdUp ? DnDCalendarStyled : CalendarStyled),
    [isMdUp]
  );

  return (
    <>
      <ResponsiveContainer
        DesktopComponent={Toolbar}
        MobileComponent={ToolbarMobile}
        resourceGroupSelectorProps={props.resourceGroupSelectorProps}
        date={props.currentDate}
        dateRange={props.dateRange}
        onViewChanged={onViewChanged}
        view={props.currentView}
        resourceView={props.resourceView}
        weekViewType={props.weekViewType}
        onResourceViewChanged={props.onResourceViewChanged}
        onWeekViewTypeChanged={props.onWeekViewTypeChanged}
        onWeekViewResourceChanged={props.onWeekViewResourceChanged}
        onDateChanged={onDateChanged}
        resources={props.allResources}
        selectedResource={props.weekResource}
        weekStatus={props.weekStatus}
        onWeekApprove={props.onWeekApprove}
        unsentNotificationsProps={props.unsentNotificationsProps}
        unreadUpdatesProps={props.unreadUpdatesProps}
        weekStatusProps={{
          ...props.weekStatusProps,
          onNavigate,
        }}
        calendarSettingsProps={{ ...props.calendarSettingsProps }}
        admin={props.admin}
        eventTypesProps={props.eventTypesProps}
        clubCoachesProps={props.clubCoachesProps}
        teamsProps={props.teamsProps}
      />
      <div style={{ position: "relative" }}>
        <Calendar
          showMultiDayTimes
          step={15}
          selectable="ignoreEvents"
          scrollToTime={moment("14:00am", "h:mma").toDate()}
          min={moment("06:00am", "HH:mma").toDate()}
          max={moment("23:59pm", "HH:mma").toDate()}
          resources={props.resources}
          onSelectSlot={props.admin ? props.onSelectSlot : null}
          onSelectEvent={props.onEventSelected}
          formats={formats}
          eventPropGetter={eventStyleGetter}
          resourceAccessor="viewResource"
          tooltipAccessor={null}
          startAccessor="start"
          endAccessor="end"
          resourceIdAccessor="_id"
          resourceTitleAccessor="name"
          rtl
          popup
          view={props.currentView}
          defaultView="day"
          onView={() => {}}
          date={props.currentDate.toDate()}
          draggableAccessor={"isDraggable"}
          localizer={localizer}
          events={props.events}
          onNavigate={onNavigate}
          onEventDrop={props.onEventDrop}
          onEventResize={props.onEventDrop}
          views={{
            day: true,
            week: props.weekViewType === "regular" ? true : CustomAgenda,
          }}
          length={agendaLength}
          toolbar={false}
          components={{
            event: renderEvent(true),
            day: {
              event: renderEvent(true),
            },
            week: {
              event:
                props.weekViewType === "regular"
                  ? renderEvent(true)
                  : ({ event }) => event,
            },
          }}
        />
        {props.isLoading && (
          <AbsoluteCenter>
            <CircularProgress size={80} thickness={2} />
          </AbsoluteCenter>
        )}
      </div>
    </>
  );
}

import React from "react";
import {
  List,
  ListItem,
  ListItemText,
  TextField,
  CircularProgress,
  ListItemSecondaryAction,
  Tooltip,
  Stack,
  ListItemButton,
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import DeleteIcon from "@mui/icons-material/Delete";
import _ from "lodash";
import moment from "moment";

import ResourceGroup from "./ResourceGroup";
import AbsoluteCenter from "../../../components/AbsoluteCenter";
import IconButtonTooltip from "../../../components/base/IconButtonTooltip";
import axios from "../../../utils/axios";
import { getAxiosErrorData } from "../../../utils/helpers";
import SnackbarNg from "../../../components/base/SnackbarNg";
import ButtonSx from "../../../components/base/ButtonSx";

const styles = {
  list: {
    border: "1px solid #EEE",
    borderRadius: 3,
    mt: 2,
    mb: 2,
  },
};

export default class ResourceGroups extends React.Component {
  state = {
    newGroupDialogOpen: false,
    groups: null,
    currentGroupName: "",
    isLoading: false,
    isSaving: false,
    error: null,
    snackbar: {},
  };

  componentDidMount = async () => {
    this.loadGroups(false);
  };

  componentDidUpdate = async (prevProps) => {
    if (prevProps.selectedGroup !== null && this.props.selectedGroup === null) {
      this.setState({ selectedGroup: null });
    }
  };

  loadGroups = async (silence = true) => {
    this.setState({ isLoading: !silence });
    let res = await axios.get("/calendar/resources");
    const resources = res.data.filter((resource) => !resource.parent); //only parents
    res = await axios.get("/calendar/resourceGroups");
    let groups = res.data;
    groups.forEach((group) => {
      group.selectedResources = [];
      group.availableResources = [];
      resources.forEach((res) => {
        if (group.resources.indexOf(res._id) === -1) {
          group.availableResources.push(res);
        }
      });

      group.resources.map((resId) => {
        const res = resources.find((r) => r._id === resId);
        if (res) {
          group.selectedResources.push(res);
        }
      });
    });

    groups = _.orderBy(groups, ["default", "name"], ["desc", "asc"]);

    this.setState({
      groups,
      resources,
      isLoading: false,
    });
  };

  addNewGroup = () => {
    this.setState((prevState) => {
      return {
        groups: [
          ...prevState.groups,
          {
            name: "",
            new: true,
            availableResources: [...prevState.resources],
            selectedResources: [],
          },
        ],
        currentGroupName: "",
      };
    });
  };

  cancelNewGroup = () => {
    this.setState((prevState) => {
      return {
        groups: prevState.groups.filter((g) => !g.new),
        currentGroupName: "",
      };
    });
  };

  saveNewGroup = async () => {
    try {
      this.setState({ isSaving: true });
      await axios.post("/calendar/resourceGroups", {
        name: this.state.currentGroupName,
        resources: [],
      });
      this.setState({
        isSaving: false,
        error: null,
        snackbar: { key: moment().format() },
      });
      await this.loadGroups();
    } catch (error) {
      const errorData = getAxiosErrorData(error);
      if ((errorData || {}).error === "notUnique") {
        this.setState({
          error: "קיימת קבוצה עם שם זהה",
          isSaving: false,
        });
      }
    }
  };

  handleGroupUpdate = async (group) => {
    // this should happen first otherwise the dropped item disappears for until the server call completes
    this.setState({
      selectedGroup: {
        ...this.state.selectedGroup,
        ...group,
      },
    });

    await axios.patch(
      `/calendar/resourceGroups/${this.state.selectedGroup._id}`,
      {
        ...this.state.selectedGroup, // this is useless: only sending the name but we never update it
        resources: group.selectedResources.map((r) => r._id),
      }
    );

    this.setState({ snackbar: { key: moment().format() } });
    this.loadGroups();
  };

  handleDeleteGroup = (group) => async (e) => {
    e.stopPropagation();
    await axios.delete(`/calendar/resourceGroups/${group._id}`);
    this.setState({
      groups: this.state.groups.filter((g) => g._id !== group._id),
    });
    this.loadGroups();
    this.setState({ snackbar: { key: moment().format() } });
  };

  handleSelectGroup = (group) => () => {
    if (!group.new) {
      this.setState({ selectedGroup: group });
      this.props.onSelectSubSetting(group);
    }
  };

  renderNewGroup = () => {
    return (
      <Stack direction="row" spacing={1} sx={{ flex: 1 }}>
        <TextField
          placeholder="שם קבוצת המגרשים"
          variant="standard"
          autoFocus
          fullWidth
          value={this.state.currentGroupName}
          onChange={(e) => this.setState({ currentGroupName: e.target.value })}
          error={this.state.error !== null}
          helperText={this.state.error}
          sx={{ flexGrow: 1 }}
        />
        <ButtonSx
          variant="outlined"
          onClick={this.cancelNewGroup}
          sx={{ width: 100, height: 35 }}
          disabled={this.state.isSaving}
        >
          ביטול
        </ButtonSx>
        <ButtonSx
          variant="outlined"
          onClick={this.saveNewGroup}
          sx={{ width: 100, height: 35 }}
          debounce
          isLoading={this.state.isSaving}
        >
          שמירה
        </ButtonSx>
      </Stack>
    );
  };

  renderGroups = () => {
    return (
      <div>
        <List sx={styles.list}>
          {this.state.groups.map((group) => (
            <ListItem
              divider
              key={group._id || "new"}
              disablePadding={group._id !== undefined}
              disableGutters={group._id !== undefined}
            >
              {group.new ? (
                this.renderNewGroup()
              ) : (
                <ListItemButton onClick={this.handleSelectGroup(group)}>
                  <>
                    <ListItemText primary={group.name} />
                    <ListItemSecondaryAction>
                      {group.default ? (
                        <Tooltip title="לא ניתן למחוק קבוצה זו">
                          <LockIcon fontSize="small" sx={{ mr: "11px" }} />
                        </Tooltip>
                      ) : (
                        <IconButtonTooltip
                          title="מחיקת קבוצה המגרשים"
                          onClick={this.handleDeleteGroup(group)}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButtonTooltip>
                      )}
                    </ListItemSecondaryAction>
                  </>
                </ListItemButton>
              )}
            </ListItem>
          ))}
        </List>

        <ButtonSx fullWidth onClick={this.addNewGroup}>
          יצירת קבוצת מגרשים חדשה
        </ButtonSx>
      </div>
    );
  };

  renderContent = () => {
    return (
      <>
        {this.state.selectedGroup ? (
          <ResourceGroup
            group={this.state.selectedGroup}
            onGroupUpdated={this.handleGroupUpdate}
          />
        ) : (
          this.renderGroups()
        )}
        <SnackbarNg
          snackKey={this.state.snackbar.key}
          open={this.state.snackbar.key !== undefined}
          onClose={() => this.setState({ snackbar: {} })}
          message="הפעולה בוצעה בהצלחה"
        />
      </>
    );
  };

  render() {
    return this.state.isLoading || !this.state.groups ? (
      <AbsoluteCenter>
        <CircularProgress />
      </AbsoluteCenter>
    ) : (
      this.renderContent()
    );
  }
}

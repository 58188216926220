import React from "react";
import {
  ListItem as MuiListItem,
  ListItemIcon,
  Divider,
  ListItemText,
  ListItemSecondaryAction,
  ListItemButton,
} from "@mui/material";

export default function ListItem(props) {
  const itemContent = (
    <>
      <ListItemIcon
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Divider
          orientation="vertical"
          sx={{
            borderWidth: 1.5,
            borderRadius: 2,
            margin: "5px",
            height: 40,
          }}
        />
        {props.icon}
      </ListItemIcon>
      <ListItemText
        primary={props.primaryText}
        secondary={props.secondaryText}
      />
      <ListItemSecondaryAction>{props.children}</ListItemSecondaryAction>
    </>
  );

  return (
    <MuiListItem dense {...props.listItemProps} disablePadding>
      {props.button ? (
        <ListItemButton>{itemContent}</ListItemButton>
      ) : (
        itemContent
      )}
    </MuiListItem>
  );
}

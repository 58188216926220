import React from "react";
import { Container, Grid } from "@mui/material";
import _ from "lodash";
import moment from "moment";
import { connect } from "react-redux";
import validator from "validator";

import MessageDialog from "../../components/MessageDialog";
import { preparePaymentFieldsForSubmit } from "../../components/store/helpers/prepareSubmitData";
import PaymentMethod from "../../components/store/PaymentMethod";
import clubConfig from "../../config/clubs";
import { validateFormNg } from "../../lib/formHelpers";
import axios from "../../utils/axios";
import FormTextField from "../base/FormTextField";
import { availablePaymentMethods } from "../../lib/clubHelper";
import { displayPrice } from "../../utils/helpers";
import { calcCreditCardPaymentsAmount } from "../../utils/paymentHelpers";
import SideDialog from "../SideDialog";
import Paper2Sx from "../Paper2Sx";
import ButtonSx from "../base/ButtonSx";

const PREFILLED = process.env.NODE_ENV === "production" ? false : false;

class StandalonePaymentForm extends React.Component {
  initialPaymentStatus = () => {
    const paymentMethods = availablePaymentMethods(
      clubConfig[this.props.selectedClub.internalName]
    );
    return {
      availablePaymentMethods: paymentMethods,
      form: {
        amount: PREFILLED ? "11" : "",
        feesAmount: 0,
        comment: PREFILLED ? "sdf" : "",
        token: null,
        ccResponse: null,
        last4: null,
        numberOfPayments: "",
        check: {
          firstCheckDate: moment(),
          checks: [],
          numberOfChecks: "",
          branch: "",
          bank: "",
        },
        wireTransfer: {
          bank: "",
          bankBranch: "",
          bankAccount: "",
        },
        name: this.props.player.parent.name,
        phone: this.props.player.parent.phone,
        email: this.props.player.parent.email,
        paymentMethod: paymentMethods.creditCard ? "creditCard" : "check",
        errors: {},
      },
    };
  };

  state = {
    loading: true,
    feedbackDialogOpen: false,
    ...this.initialPaymentStatus(),
  };

  formSettings = {
    amount: {
      validator: (amount) => amount && amount >= 1,
    },
    phone: {
      validator: (phone) => validator.isMobilePhone(phone, "he-IL"),
    },
    name: {
      // isAlpha doesn't support hebrew
      validator: (name) => name.length >= 5,
      errorMessages: { invalid: "יש להזין שם מלא" },
    },
    email: {
      validator: validator.isEmail,
      // required: false,
    },
    comment: {
      validator: (comment) => comment.length > 1,
    },
  };

  handleSubmit = async () => {
    const errors = this.validate(this.state.form);

    this.setState((prevState) => {
      return {
        form: { ...prevState.form, errors },
        instaValidate: true,
      };
    });

    if (Object.keys(errors).length > 0) {
      return;
    }

    const data = {
      clubParentId: this.props.player.parent._id,
      payer: {
        name: this.state.form.name,
        phone: this.state.form.phone,
        email: this.state.form.email,
      },
      comment: this.state.form.comment,
      payment: preparePaymentFieldsForSubmit(
        {
          totals: {
            totalCost: this.state.form.amount,
            totalPaymentAmounts: this.state.form.amount,
          },
        },
        this.state.form
      ),
      merchandise: [],
      subscriptionPayments: [
        {
          subscriptionId: this.props.subscription._id,
          price: this.state.form.amount,
          feesAmount: this.state.form.feesAmount,
          subscriptionAmount: this.state.form.subscriptionAmount,
        },
      ],
    };

    this.setState({ isSending: true });

    try {
      await axios.post(`/store/purchases`, data);
      this.setState({
        isSending: false,
        feedbackDialogOpen: true,
        success: true,
        ...this.initialPaymentStatus(),
      });
    } catch (error) {
      this.setState({
        isSending: false,
        feedbackDialogOpen: true,
        success: false,
      });
    }
  };

  validate = (form) => {
    let { formErrors } = validateFormNg(form, this.formSettings);

    if (!form.paymentMethod) {
      formErrors.paymentMethod = "יש לבחור אמצעי תשלום";
    }

    switch (form.paymentMethod) {
      case "creditCard":
        if (!form.token) {
          formErrors.cc = "שדה חובה";
        }

        if (!form.ccResponse || form.ccResponse.Response !== "000") {
          formErrors.cc = "שדה חובה";
        }

        if (!form.numberOfPayments) {
          formErrors.numberOfPayments = "שדה חובה";
        }
        break;

      case "check":
        if (!form.check.numberOfChecks) {
          formErrors.numberOfChecks = "שדה חובה";
        } else {
          const totalPrice = form.amount;
          const totalCheckPrice =
            Math.round(_.sum(form.check.checks.map((c) => c.amount)) * 100) /
            100;
          const totalChecksDiff = totalPrice - totalCheckPrice;

          if (totalChecksDiff !== 0) {
            formErrors.totalChecksDiff = "סך כל הצ׳קים לא תואם";
          }

          const checkErrors = {};

          form.check.checks.forEach((check, index) => {
            if (check.amount <= 0) {
              checkErrors[`${index}Amount`] = "שדה לא תקין";
            }
            if (check.checkNumber.length < 2) {
              checkErrors[`${index}CheckNumber`] = "שדה לא תקין";
            }
            if (!check.date) {
              checkErrors[`${index}Date`] = "שדה חובה";
            }
            if (check.accountNumber.length < 2) {
              checkErrors[`${index}AccountNumber`] = "שדה לא תקין";
            }
            if (check.branch.length < 2) {
              checkErrors[`${index}Branch`] = "שדה לא תקין";
            }
            if (!check.bank) {
              checkErrors[`${index}Bank`] = "שדה חובה";
            }
          });

          if (Object.keys(checkErrors).length > 0) {
            formErrors.checks = checkErrors;
          }
        }
        break;

      case "wireTransfer":
        if (form.wireTransfer.bank === "") {
          formErrors.wireTransferBank = "שדה חובה";
        }

        if (form.wireTransfer.bankBranch === "") {
          formErrors.wireTransferBankBranch = "שדה חובה";
        }

        if (form.wireTransfer.bankAccount === "") {
          formErrors.wireTransferBankAccount = "שדה חובה";
        }
        break;

      default:
        break;
    }

    return formErrors;
  };

  handleFieldChange = async (updates) => {
    let form = {
      ...this.state.form,
      ...updates,
    };

    if (form.paymentMethod === "creditCard") {
      const defaultCalcMethod =
        "numberOfPayments" in updates || "amount" in updates;

      const feesAmount = _.min([form.amount, this.props.subscription.feesDebt]);

      form = {
        ...form,
        ...calcCreditCardPaymentsAmount(
          form.amount,
          defaultCalcMethod ? undefined : form.firstPaymentAmount,
          form.numberOfPayments,
          feesAmount
        ),
      };
    } else {
      form.firstPaymentAmount = undefined;
      form.basePaymentAmount = undefined;
    }

    let errors = form.errors;
    if (this.state.instaValidate) {
      errors = this.validate(form);
    }
    this.setState({
      form: {
        ...form,
        errors,
      },
    });
  };

  render() {
    const defaultTextFieldParams = {
      form: this.state.form,
      onChange: this.handleFieldChange,
      errors: this.state.form.errors,
      formSettings: this.formSettings,
    };

    const actions = (
      <ButtonSx
        variant="contained"
        onClick={this.handleSubmit}
        isLoading={this.state.isSending}
        buttonProps={{ fullWidth: true }}
        sx={{ width: { sm: 200 } }}
        debounce
      >
        תשלום
      </ButtonSx>
    );

    return (
      <>
        <MessageDialog
          open={this.state.feedbackDialogOpen}
          title={this.state.success ? "התשלום בוצע בהצלחה" : "התשלום נכשל"}
          type={this.state.success ? "success" : "error"}
          okButtonText="סגירה"
          onOk={() => {
            this.setState({ feedbackDialogOpen: false });
            this.props.onComplete();
          }}
        />

        <SideDialog
          open={this.props.open}
          onClose={this.props.onClose}
          title={`תשלום עבור ${this.props.player.name} / ${this.props.subscription.team.name} `}
          actions={actions}
          sx={{ width: null }}
          dialogProps={{ fullScreen: true }}
          coloredTitle
        >
          <Container maxWidth="lg" sx={{ p: 0 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Paper2Sx externalTitle="פרטי המשלם" paperSx={{ p: 2 }}>
                  <Grid container>
                    <Grid item xs={12}>
                      <FormTextField
                        field="name"
                        label="שם"
                        {...defaultTextFieldParams}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormTextField
                        field="phone"
                        label="טלפון"
                        {...defaultTextFieldParams}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormTextField
                        field="email"
                        label="אימייל"
                        {...defaultTextFieldParams}
                      />
                    </Grid>
                  </Grid>
                </Paper2Sx>
              </Grid>
              <Grid item xs={12} md={6}>
                <Paper2Sx
                  externalTitle="פרטי התשלום"
                  paperSx={{ height: 200, p: 2 }}
                >
                  <Grid container>
                    <Grid item xs={12}>
                      <FormTextField
                        label="סכום לתשלום"
                        field="amount"
                        {...defaultTextFieldParams}
                        textFieldProps={{
                          value: this.state.form.amount.toString(),
                          type: "number",
                          onChange: (e) => {
                            // remove decimal part after 2 digits 12.12345 -> 12.12
                            const amount =
                              Math.floor(parseFloat(e.target.value) * 100) /
                              100;
                            const feesAmount = _.min([
                              amount,
                              this.props.subscription.feesDebt,
                            ]);
                            const subscriptionAmount = amount - feesAmount;
                            this.handleFieldChange({
                              amount,
                              feesAmount,
                              subscriptionAmount,
                            });
                          },
                          ...(this.state.form.errors.feesAmount === undefined &&
                          this.state.form.feesAmount > 0
                            ? {
                                helperText: `${displayPrice(
                                  this.state.form.feesAmount
                                )} ישולמו לטובת דמי הרישום`,
                              }
                            : {}),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormTextField
                        field="comment"
                        label="מטרת התשלום"
                        {...defaultTextFieldParams}
                      />
                    </Grid>
                  </Grid>
                </Paper2Sx>
              </Grid>
              <Grid item xs={12} md={12}>
                <Paper2Sx externalTitle="אמצעי תשלום" paperSx={{ p: 2 }}>
                  <PaymentMethod
                    state={this.state.form}
                    onChange={this.handleFieldChange}
                    paymentAmount={this.state.form.amount}
                    clubConfig={
                      clubConfig[this.props.selectedClub.internalName]
                    }
                    availablePaymentMethods={this.state.availablePaymentMethods}
                    purchaseType={
                      this.state.form.subscriptionAmount > 0
                        ? "subscription"
                        : "fees"
                    }
                  />
                </Paper2Sx>
              </Grid>
            </Grid>
          </Container>
        </SideDialog>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedClub: state.auth.selectedClub,
  };
}

export default connect(mapStateToProps)(StandalonePaymentForm);

import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Grid, List, Paper, Typography } from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/NavigateBefore";
import ArrowLeftIcon from "@mui/icons-material/NavigateNext";

const styles = {
  droppablePaper: {
    height: "70vh",
    overflow: "auto",
    width: "100%",
  },
};

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: 10,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? "#EFEFEF" : "white",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "lightblue" : "#ebecf0",
  padding: grid,
  width: "100%",
  height: "100%",
  overflow: "auto",
  borderRadius: 5,
});

export default function ResourceGroup(props) {
  const onDragEnd = (result) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        props.group[source.droppableId],
        source.index,
        destination.index
      );

      if (source.droppableId === "selectedResources") {
        props.onGroupUpdated({ selectedResources: items });
      }
    } else {
      const result = move(
        props.group[source.droppableId],
        props.group[destination.droppableId],
        source,
        destination
      );

      props.onGroupUpdated({
        selectedResources: result.selectedResources,
        availableResources: result.availableResources,
      });
    }
  };

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Grid container alignItems="center" justifyContent="center">
        <Grid item xs={5} container justifyContent="center">
          <Typography variant="h6">מגרשים שנבחרו</Typography>
        </Grid>
        <Grid item xs={2} />

        <Grid item xs={5} container justifyContent="center">
          <Typography variant="h6">יתר המגרשים</Typography>
        </Grid>

        <Grid item xs={5}>
          <Paper sx={styles.droppablePaper}>
            <Droppable droppableId="selectedResources">
              {(provided, snapshot) => (
                <List
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  {props.group.selectedResources.map((item, index) => (
                    <Draggable
                      key={item._id}
                      draggableId={item._id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <Paper
                          variant="outlined"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          {item.name}
                        </Paper>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </List>
              )}
            </Droppable>
          </Paper>
        </Grid>
        <Grid
          item
          xs={2}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <ArrowRightIcon style={{ fontSize: 60 }} />
          <ArrowLeftIcon style={{ fontSize: 60 }} />
        </Grid>
        <Grid item xs={5}>
          <Paper sx={styles.droppablePaper}>
            <Droppable
              droppableId="availableResources"
              isDropDisabled={props.group.default}
            >
              {(provided, snapshot) => (
                <List
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  {props.group.availableResources.map((item, index) => (
                    <Draggable
                      key={item._id}
                      draggableId={item._id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <Paper
                          variant="outlined"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          {item.name}
                        </Paper>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </List>
              )}
            </Droppable>
          </Paper>
        </Grid>
      </Grid>
    </DragDropContext>
  );
}

import validator from "validator";
import moment from "moment";
import _ from "lodash";
import { roundPrice } from "../../utils/helpers";

export const validateSubscriptionField = (subscription, field, errors = {}) => {
  switch (field) {
    case "level":
    case "subLevel":
    case "teamId":
      if (!subscription[field]) {
        errors[field] = "required";
      }
      break;

    case "startDate":
      if (!subscription.startDate) {
        errors.startDate = "שדה חובה";
      } else if (subscription.product._id !== "external") {
        if (
          moment(subscription.startDate).isBefore(
            moment(subscription.product.subscriptionDetails.startDate).startOf(
              "day"
            )
          ) ||
          moment(subscription.startDate).isAfter(
            moment(subscription.product.subscriptionDetails.endDate).endOf(
              "day"
            )
          )
        ) {
          errors.startDate = "תאריך לא תקין";
        }
      }
      break;

    default:
      throw new Error("field not found");
  }

  return errors[field];
};

export const validatePlayerField = (player, field, errors = {}) => {
  let name, phone, dob, guestName, guestPhone;
  switch (field) {
    case "firstName":
    case "lastName":
      name = player[field].trim();
      if (name === "") {
        errors[field] = "שדה חובה";
      } else if (name.length < 2) {
        errors[field] = "שדה לא תקין";
      }
      break;

    case "id":
      if (player.id.trim() === "") {
        errors.id = "שדה חובה";
      } else if (!validator.isIdentityCard(player.id, "he-IL")) {
        errors.id = "שדה לא תקין";
      } else if (player.isIdUnique === false) {
        // can also be loading
        errors.id = "קיים במועדון ספורטאי עם תעודת זהות זו";
      }
      break;

    case "passportNumber":
      if (!player.isPassportNumberUnique) {
        errors.passportNumber = "שדה חובה";
      }
      break;

    case "phone":
      phone = player.phone.trim();
      if (phone !== "" && !validator.isMobilePhone(phone, "he-IL")) {
        errors.phone = "שדה לא תקין";
      } else if (player.isPhoneUnique === false) {
        // can also be loading
        errors.phone = "קיים במועדון ספורטאי עם מספר טלפון זה";
      }
      break;

    case "class":
    case "gender":
      if (player[field] === "") {
        errors[field] = "שדה חובה";
      }
      break;

    case "dob":
      dob = moment([player.dobYear, player.dobMonth - 1, player.dobDay]);

      if (!dob.isValid()) {
        errors.dob = "תאריך לא תקין";
      }
      break;

    case "hasMedicalIssues":
      if (
        player.hasMedicalIssues !== true &&
        player.hasMedicalIssues !== false
      ) {
        errors.hasMedicalIssues = "שדה חובה";
      }
      break;

    case "medicalIssues":
      if (player.hasMedicalIssues && player.medicalIssues.trim() === "") {
        errors.medicalIssues = "שדה חובה";
      }
      break;

    case "guestName":
      guestName = player.guestName.trim();
      if (guestName === "") {
        errors.guestName = "שדה חובה";
      } else if (guestName.length < 2) {
        errors.guestName = "שדה לא תקין";
      }
      break;

    case "guestTeam":
      //nothing to validate
      break;

    case "guestPhone":
      guestPhone = player.guestPhone.trim();
      if (guestPhone !== "" && !validator.isMobilePhone(guestPhone, "he-IL")) {
        errors.guestPhone = "שדה לא תקין";
      }
      break;

    case "clubPlayerId":
      if (player.viewType === "existing" && !player.clubPlayerId) {
        errors.clubPlayerId = "שדה חובה";
      }
      break;

    default:
      throw new Error("field not found");
  }

  return errors[field];
};

export const validateSubscription = (subscription) => {
  const errors = {};

  validateSubscriptionField(subscription, "level", errors);
  validateSubscriptionField(subscription, "subLevel", errors);
  validateSubscriptionField(subscription, "teamId", errors);
  validateSubscriptionField(subscription, "startDate", errors);

  return errors;
};

export const validatePlayer = (player) => {
  const errors = {};

  if (player.viewType === "guest") {
    validatePlayerField(player, "guestName", errors);
    validatePlayerField(player, "guestTeam", errors);
    validatePlayerField(player, "guestPhone", errors);
  } else if (player.viewType === "existing") {
    validatePlayerField(player, "clubPlayerId", errors);
  } else {
    validatePlayerField(player, "firstName", errors);
    validatePlayerField(player, "lastName", errors);
    if (player.notIsraeli) {
      validatePlayerField(player, "passportNumber", errors);
    } else {
      validatePlayerField(player, "id", errors);
    }
    validatePlayerField(player, "phone", errors);
    validatePlayerField(player, "class", errors);
    validatePlayerField(player, "dob", errors);
    validatePlayerField(player, "gender", errors);
    validatePlayerField(player, "hasMedicalIssues", errors);
    validatePlayerField(player, "medicalIssues", errors);
  }

  return errors;
};

/// PARENT ///

export const validateParentField = (parent, field, errors = {}) => {
  let value, email, phone, secondParentName, secondParentPhone, guestEmail;
  switch (field) {
    case "city":
    case "firstName":
    case "lastName":
    case "guestName":
    case "street":
      value = parent[field].trim();
      if (value === "") {
        errors[field] = "שדה חובה";
      } else if (value.length < 2) {
        errors[field] = "שדה לא תקין";
      }
      break;

    case "houseNumber":
      if (parent.houseNumber.trim() === "") {
        errors.houseNumber = "שדה חובה";
      }
      break;

    case "email":
      email = parent.email.trim();
      if (email === "") {
        errors.email = "שדה חובה";
      } else if (!validator.isEmail(email)) {
        errors.email = "שדה לא תקין";
      }
      break;

    case "phone":
      phone = parent.phone.trim();
      if (phone === "") {
        errors.phone = "שדה חובה";
      } else if (!validator.isMobilePhone(phone, "he-IL")) {
        errors.phone = "שדה לא תקין";
      } else if (parent.isPhoneUnique === false) {
        // can also be loading
        errors.phone = "קיים במועדון הורה עם מספר טלפון זה";
      }
      break;

    case "secondParentName":
      secondParentName = parent.secondParentName.trim();
      if (secondParentName !== "" && secondParentName.length < 2) {
        errors.secondParentName = "שדה לא תקין";
      }
      break;

    case "secondParentPhone":
      secondParentPhone = parent.secondParentPhone.trim();
      if (
        secondParentPhone !== "" &&
        !validator.isMobilePhone(secondParentPhone, "he-IL")
      ) {
        errors.secondParentPhone = "שדה לא תקין";
      }
      break;

    case "id":
      if (parent.id.trim() === "") {
        errors.id = "שדה חובה";
      } else if (!validator.isIdentityCard(parent.id, "he-IL")) {
        errors.id = "שדה לא תקין";
      } else if (parent.isIdUnique === false) {
        // can also be loading
        errors.id = "קיים במועדון הורה עם תעודת זהות זו";
      }
      break;

    case "guestEmail":
      guestEmail = parent.guestEmail.trim();
      if (guestEmail !== "" && !validator.isEmail(guestEmail)) {
        errors.guestEmail = "שדה לא תקין";
      }
      break;

    case "guestPhone":
      if (
        parent.guestPhone.trim() !== "" &&
        !validator.isMobilePhone(parent.guestPhone, "he-IL")
      ) {
        errors.guestPhone = "שדה לא תקין";
      }
      break;

    case "passportNumber":
      if (!parent.isPassportNumberUnique) {
        errors.passportNumber = "שדה חובה";
      }
      break;

    default:
      throw new Error("field not found");
  }

  return errors[field];
};

export const validateParent = (parent, guestMode) => {
  const errors = {};

  if (guestMode) {
    validateParentField(parent, "guestName", errors);
    validateParentField(parent, "guestPhone", errors);
    validateParentField(parent, "guestEmail", errors);
  } else if (!parent.clubParentId) {
    validateParentField(parent, "firstName", errors);
    validateParentField(parent, "lastName", errors);
    validateParentField(parent, "street", errors);
    validateParentField(parent, "city", errors);
    validateParentField(parent, "houseNumber", errors);
    validateParentField(parent, "email", errors);
    validateParentField(parent, "phone", errors);
    validateParentField(parent, "secondParentName", errors);
    validateParentField(parent, "secondParentPhone", errors);
    if (parent.notIsraeli) {
      validateParentField(parent, "passportNumber", errors);
    } else {
      validateParentField(parent, "id", errors);
    }
  }

  return errors;
};

//// PAYMENT ////

export const validatePaymentField = (payment, cart, field, errors = {}) => {
  let totalPrice, totalChecksDiff, checkErrors;
  switch (field) {
    case "cc":
      if (!payment.token) {
        errors.cc = "שדה חובה";
      } else if (!payment.ccResponse || payment.ccResponse.Response !== "000") {
        errors.cc = "שדה חובה";
      }
      break;

    case "numberOfPayments":
      if (!payment.numberOfPayments) {
        errors.numberOfPayments = "שדה חובה";
      }
      break;

    case "numberOfChecks":
      if (!payment.check.numberOfChecks) {
        errors.numberOfChecks = "שדה חובה";
      }
      break;

    case "checks":
      totalPrice = cart.totals.totalPaymentAmounts;
      totalChecksDiff =
        totalPrice - _.sum(payment.check.checks.map((c) => c.amount));

      if (roundPrice(totalChecksDiff) !== 0) {
        errors.totalChecksDiff = "סך כל הצ׳קים לא תואם";
      }

      checkErrors = {};

      payment.check.checks.forEach((check, index) => {
        if (check.amount <= 0) {
          checkErrors[`${index}Amount`] = "שדה לא תקין";
        }
        if (check.checkNumber.length < 2) {
          checkErrors[`${index}CheckNumber`] = "שדה לא תקין";
        }
        if (!check.date) {
          checkErrors[`${index}Date`] = "שדה חובה";
        }
        if (check.accountNumber.trim().length < 2) {
          checkErrors[`${index}AccountNumber`] = "שדה לא תקין";
        }
        if (check.branch.trim().length < 2) {
          checkErrors[`${index}Branch`] = "שדה לא תקין";
        }
        if (!check.bank) {
          checkErrors[`${index}Bank`] = "שדה חובה";
        }
      });

      if (Object.keys(checkErrors).length > 0) {
        errors.checks = checkErrors;
      }
      break;

    case "bank":
      if (payment.wireTransfer.bank === "") {
        errors.wireTransferBank = "שדה חובה";
      }
      break;

    case "bankBranch":
      if (payment.wireTransfer.bankBranch.trim() === "") {
        errors.wireTransferBankBranch = "שדה חובה";
      }
      break;

    case "bankAccount":
      if (payment.wireTransfer.bankAccount.trim() === "") {
        errors.wireTransferBankAccount = "שדה חובה";
      }
      break;

    default:
      throw new Error("field not found");
  }

  return errors[field];
};

export const validatePayment = (payment, cart) => {
  const errors = {};

  if (cart.totals.totalPaymentAmounts > 0) {
    switch (payment.paymentMethod) {
      case "creditCard":
        validatePaymentField(payment, cart, "cc", errors);
        validatePaymentField(payment, cart, "numberOfPayments", errors);
        break;

      case "check":
        validatePaymentField(payment, cart, "numberOfChecks", errors);
        validatePaymentField(payment, cart, "checks", errors);
        break;

      case "wireTransfer":
        validatePaymentField(payment, cart, "bank", errors);
        validatePaymentField(payment, cart, "bankBranch", errors);
        validatePaymentField(payment, cart, "bankAccount", errors);
        break;

      default:
        break;
    }
  }

  return errors;
};

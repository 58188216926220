import React, { Component } from "react";
import { Button, CircularProgress, List } from "@mui/material";
import _ from "lodash";
import moment from "moment";

import axios from "../../../utils/axios";
import AbsoluteCenter from "../../../components/AbsoluteCenter";
import CalendarResource from "./CalendarResource";
import { getAxiosErrorData } from "../../../utils/helpers";
import SnackbarNg from "../../../components/base/SnackbarNg";

export default class CalendarResourceSettings extends Component {
  state = {
    isLoading: false,
    isSaving: false,
    resources: null,
    editingItemIndex: -1,
    editingItem: null,
    snackbarKey: null,
    error: null,
  };

  componentDidMount = async () => {
    this.loadResources(false);
  };

  loadResources = async (silence = true) => {
    this.setState({ isLoading: !silence });
    const res = await axios.get(`/calendar/resources`);

    const allResources = res.data;
    let resources = allResources.filter((r) => !r.parent);
    resources = _.sortBy(resources, (r) => -moment(r.createdAt).valueOf());

    resources = resources.map((resource) => {
      const subResources = resource.subResources.map((subResource) =>
        allResources.find((r) => r._id === subResource)
      );
      return [resource, ...subResources];
    });

    resources = _.flatten(resources);

    this.setState({ isLoading: false, resources });
  };

  handleAddResource =
    (index, parent = null) =>
    () => {
      const resource = { name: "" };
      if (parent) {
        resource.parent = parent._id;
        resource.name = `${parent.name} ${parent.subResources.length + 1}`;
      }
      this.setState((prevState) => {
        let resources = [...prevState.resources];
        resources.splice(index, 0, resource);
        return {
          resources,
          editingItemIndex: index,
          editingItem: resource,
        };
      });
    };

  renderContent = () => (
    <div>
      <Button
        fullWidth
        variant="outlined"
        onClick={this.handleAddResource(0)}
        disabled={this.state.editingItemIndex > -1}
      >
        הוספת מגרש חדש
      </Button>
      {this.renderResourceList()}
    </div>
  );

  handleSetEditing = (resource, index) => () => {
    this.handleCancelEditing();
    this.setState({ editingItemIndex: index, editingItem: { ...resource } });
  };

  handleCancelEditing = () => {
    this.setState((prevState) => {
      let resources = [...prevState.resources];
      //editing new item => remove it
      if (this.state.editingItem && !this.state.editingItem._id) {
        resources.splice(this.state.editingItemIndex, 1);
      }
      return {
        editingItemIndex: -1,
        editingItem: null,
        resources,
        error: null,
      };
    });
  };

  handleEditingCompleted = async () => {
    if (this.state.editingItem.name.length < 2) {
      this.setState({ error: "שדה לא תקין" });
      return;
    }

    this.setState({ error: null });
    const success = await this.saveResource(this.state.editingItem);
    if (success) {
      this.handleCancelEditing();
      this.loadResources();
    }
  };

  handleResourceNameChanged = (e) => {
    const name = e.target.value;
    this.setState((prevState) => ({
      editingItem: { ...prevState.editingItem, name },
    }));
  };

  saveResource = async (resource) => {
    try {
      this.setState({ isSaving: true });
      if (resource._id) {
        await axios.patch(`/calendar/resources/${resource._id}`, {
          name: resource.name,
        });
      } else {
        await axios.post(`/calendar/resources/`, {
          name: resource.name,
          ...(resource.parent ? { parentId: resource.parent } : {}),
        });
      }
      this.setState({ isSaving: false, snackbarKey: moment().format() });
      return true;
    } catch (error) {
      const errorData = getAxiosErrorData(error);
      console.log(errorData);
      if ((errorData || {}).error === "notUnique") {
        this.setState({ error: "קיים מגרש עם שם זהה", isSaving: false });
      } else {
        this.setState({ error: "אירעה שגיאה", isSaving: false });
      }
      return false;
    }
  };

  renderResourceList = () => (
    <List>
      {this.state.resources.map((resource, index) => {
        const editing = index === this.state.editingItemIndex;

        return (
          <CalendarResource
            key={resource._id || "new"}
            resource={editing ? this.state.editingItem : resource}
            onStartEditing={this.handleSetEditing(resource, index)}
            onCancelEditing={this.handleCancelEditing}
            onCompleteEditing={this.handleEditingCompleted}
            isEditing={editing}
            isSaving={this.state.isSaving && editing}
            onNameChange={this.handleResourceNameChanged}
            onAddNewSubResource={this.handleAddResource(index + 1, resource)}
            disabled={this.state.editingItemIndex > -1 && !editing}
            error={editing ? this.state.error : null}
          />
        );
      })}
    </List>
  );

  render() {
    return (
      <div>
        {this.state.isLoading || !this.state.resources ? (
          <AbsoluteCenter>
            <CircularProgress />
          </AbsoluteCenter>
        ) : (
          this.renderContent()
        )}
        <SnackbarNg
          snackKey={this.state.snackbarKey}
          open={this.state.snackbarKey !== null}
          onClose={() => this.setState({ snackbarKey: null })}
          severity="success"
          message="הפעולה בוצעה בהצלחה"
        />
      </div>
    );
  }
}

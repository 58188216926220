import React from "react";
import { TextField, CircularProgress, Button, Box } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";

import ListItem from "../../../components/ListItem";
import IconButtonTooltip from "../../../components/base/IconButtonTooltip";

const styles = {
  button: {
    marginLeft: "5px",
    width: 70,
  },
};

export default function CalendarResource(props) {
  const defaultListItemProps = {
    listItemProps: {
      divider: true,
    },
  };

  const renderPrimary = () => {
    if (!props.isEditing) {
      return <div>{props.resource.name}</div>;
    }

    return (
      <TextField
        variant="standard"
        value={props.resource.name}
        onChange={props.onNameChange}
        placeholder="שם המגרש"
        error={props.error !== null}
        helperText={props.error}
        style={{ width: 250 }}
      />
    );
  };

  const renderResource = () => {
    return (
      <ListItem {...defaultListItemProps} primaryText={renderPrimary()}>
        {props.isSaving && <CircularProgress />}
        {!props.isSaving &&
          (props.isEditing ? (
            <div>
              <Button
                variant="outlined"
                onClick={props.onCancelEditing}
                disabled={props.disabled}
                sx={styles.button}
              >
                ביטול
              </Button>
              <Button
                variant="outlined"
                onClick={props.onCompleteEditing}
                disabled={props.disabled}
                sx={styles.button}
              >
                שמירה
              </Button>
            </div>
          ) : (
            <span>
              {!props.resource.parent && (
                <IconButtonTooltip
                  title="הוספת חלוקת מגרש"
                  iconButtonProps={{ size: "small" }}
                  onClick={props.onAddNewSubResource}
                  disabled={props.disabled}
                >
                  <AddIcon />
                </IconButtonTooltip>
              )}
              <IconButtonTooltip
                title="עריכה"
                iconButtonProps={{ size: "small" }}
                onClick={props.onStartEditing}
                disabled={props.disabled}
              >
                <EditIcon />
              </IconButtonTooltip>
            </span>
          ))}
      </ListItem>
    );
  };

  return (
    <Box
      sx={{
        ml: props.resource.parent ? 3 : 0,
      }}
    >
      {renderResource()}
    </Box>
  );
}

import React, { useMemo } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { connect } from "react-redux";
import { FaTag } from "react-icons/fa6";
import { FaDollarSign } from "react-icons/fa";
import _ from "lodash";
import ReactApexChart from "react-apexcharts";

import { displayPrice, displaySeason } from "../../../utils/helpers";
import RevenueIcon from "./RevenueIcon";
import { clubCategories } from "../../../utils/dictionaries";
import Paper2Sx from "../../../components/Paper2Sx";

function RevenueSuperClubSeasonSummary({
  seasons,
  isLoading,
  clubsHash,
  year,
}) {
  const totalPaid = useMemo(() => _.sumBy(seasons, "totalPaid"), [seasons]);
  const chartData = useMemo(() => {
    const labels = seasons.map(
      (s) =>
        clubsHash[s.season.club].clubConfig.general.branchName ||
        clubCategories[clubsHash[s.season.club].category]
    );
    const series = seasons.map((s) => s.totalPaid);

    return { labels, series };
  }, [seasons, clubsHash]);

  return (
    <Paper2Sx paperSx={styles.container}>
      <Grid container justifyContent="space-between">
        <Grid item container xs={12} alignItems="center" sx={styles.header}>
          <Grid item xs style={{ border: "0px solid" }}>
            <Typography variant="h5" component={"span"} sx={styles.title}>
              <span
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <RevenueIcon color="#00e396">
                  <FaTag style={{ color: "FFF", fontSize: 40, padding: 2 }} />
                </RevenueIcon>
                <div>{displaySeason(year)} - כל הענפים</div>
                <div>
                  <Typography variant="subtitle1"> עונה</Typography>
                </div>
              </span>
            </Typography>
          </Grid>
          <Grid item xs style={{ border: "0px solid" }}>
            <Typography variant="h5" component={"span"} sx={styles.title}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <RevenueIcon color="#008ffb">
                    <FaDollarSign style={{ color: "FFF", fontSize: 40 }} />
                  </RevenueIcon>

                  <div>{displayPrice(totalPaid)}</div>
                  <div>
                    <Typography variant="subtitle1">הכנסות</Typography>
                  </div>
                </span>
              </div>
            </Typography>
          </Grid>

          <Grid item xs={5}>
            {totalPaid === 0 ? (
              <Box sx={styles.chartTextNoData}>
                <Typography>לא קיימות הכנסות לעונה זו</Typography>
              </Box>
            ) : (
              <ReactApexChart
                options={{
                  chart: { type: "pie" },
                  labels: chartData.labels,
                  tooltip: {
                    enabled: true,
                    y: {
                      formatter: function (value) {
                        return displayPrice(value);
                      },
                    },
                  },
                  legend: {
                    formatter: function (value) {
                      return _.truncate(value, { length: 25 });
                    },
                    width: 180,
                  },
                  noData: {
                    text: isLoading
                      ? "טוען נתונים..."
                      : "לא נמכרו מוצרים בתקופה שנבחרה",
                    align: "center",
                    verticalAlign: "middle",
                    offsetX: 0,
                    offsetY: 0,
                  },
                }}
                height={200}
                series={chartData.series}
                type="pie"
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Paper2Sx>
  );
}

const styles = {
  container: {
    minHeight: 200,
    display: "flex",
    alignItems: "center",
    marginBottom: 30,
    position: "relative",
  },
  chartTextNoData: {
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: 55,
  },
};

function mapStateToProps(state) {
  return {
    clubsHash: state.auth.clubsHash,
  };
}

export default connect(mapStateToProps)(RevenueSuperClubSeasonSummary);

import React from "react";
import { TextField, ListSubheader, Divider } from "@mui/material";

import { teamTypes } from "../../../utils/dictionaries";
import MenuItem from "../../../components/base/MenuItem";

export default function TeamSelect(props) {
  const { event } = props;
  let selectedTeam;
  if (props.readOnly) {
    selectedTeam = props.teams.find((t) => t._id === event.team);
    if (!selectedTeam) {
      selectedTeam = props.completedTeams.find((t) => t._id === event.team);
    }
  }

  return (
    <TextField
      variant="standard"
      select={!props.readOnly}
      fullWidth
      label="קבוצה"
      margin="dense"
      error={props.errors.team !== undefined}
      value={props.readOnly ? selectedTeam?.name : event.team}
      onChange={(event) => props.onFieldUpdated("team", event.target.value)}
      inputProps={{
        readOnly: props.readOnly,
        name: "team",
        id: "team",
      }}
    >
      {event.type === "other" && (
        <MenuItem key="noTeam" value={null}>
          ללא קבוצה
        </MenuItem>
      )}
      <Divider light />

      {["league", "school", "camp"].map((type) => {
        return (
          props.teamsByType[type] &&
          props.teamsByType[type].length > 0 && [
            <ListSubheader
              key={type}
              disableSticky
              style={{
                display: "flex",
                justifyContent: "flex-end",
                fontSize: 20,
              }}
            >
              {(teamTypes[props.clubCategory] || teamTypes.default)[type]}
            </ListSubheader>,
            <Divider key={`${type}divider`} />,
            ...props.teamsByType[type].map((team) => (
              <MenuItem key={team._id} value={team._id}>
                {team.name}
              </MenuItem>
            )),
          ]
        );
      })}
    </TextField>
  );
}

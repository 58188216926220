import React from "react";
import { Select, FormControl, InputLabel } from "@mui/material";

import MenuItem from "../../../components/base/MenuItem";

export default function ResourceGroupSelector(props) {
  const resourceGroups = props.resourceGroups || [];
  const handleSelectGroup = (e) => {
    const group = resourceGroups.find((g) => g._id === e.target.value);
    props.onResourceGroupSelected(group);
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="resourceGroupSelect">מגרשים</InputLabel>
      <Select
        labelId="resourceGroupSelect"
        variant="outlined"
        value={(props.selectedResourceGroup || {})._id || "ph"}
        onChange={handleSelectGroup}
        margin="dense"
        size="small"
        label="מגרשים"
        sx={{ height: 35, fontSize: "0.875rem" }}
      >
        {resourceGroups.length === 0 && <MenuItem value="ph" />}
        {resourceGroups.map((group) => (
          <MenuItem key={group._id} value={group._id}>
            {group.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
